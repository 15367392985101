
































































import { Component, Vue } from "vue-property-decorator";
import { IconsType } from "@/types";
import { mdiFilterVariant } from "@mdi/js";
import { namespace } from "vuex-class";
const FiltersModule = namespace("FiltersModule");
const AppModule = namespace("AppModule");
@Component({
  components: {
    FilterUser: () => import("@/components/Filters/FilterUser.vue"),
    FilterItem: () => import("@/components/Filters/FilterItem.vue"),
    FilterStatus: () => import("@/components/Filters/FilterStatus.vue"),
    PickerDate: () => import("@/components/PickerDate.vue")
  }
})
export default class MenuFilters extends Vue {
  //
  // COMPUTED
  @AppModule.Getter
  public smallScreen!: string;
  @FiltersModule.Getter
  public filtersCount!: number;
  get dateBegin() {
    return this.$store.state.FiltersModule.filters.dateBegin;
  }
  set dateBegin(payload: string) {
    this.$store.commit("FiltersModule/FILTER_DATE_BEGIN", payload);
    this.onFilter();
  }
  get dateEnd() {
    return this.$store.state.FiltersModule.filters.dateEnd;
  }
  set dateEnd(payload: string) {
    this.$store.commit("FiltersModule/FILTER_DATE_END", payload);
    this.onFilter();
  }
  //
  // DATA
  private dialog = false;
  private icons: IconsType = {
    filter: mdiFilterVariant
  };
  //
  // METHODS
  public reset() {
    this.$store.commit("FiltersModule/FILTER_RESET");
    this.onFilter();
  }
  public onUpdate() {
    this.dialog = false;
    this.$emit("filter");
  }
  onFilter() {
    if (!this.smallScreen) this.$emit("filter");
  }
}
